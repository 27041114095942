/** @format */

import React from 'react';

interface ExploreGridItemProp {
	iconSrc: string;
	title: string;
	description: string;
}

const ExploreGridItem: React.FC<ExploreGridItemProp> = ({ iconSrc, title, description }) => {
	return (
		<>
			<div className='flex flex-col space-y-5 p-5'>
				<img src={iconSrc} alt={title} className='w-36 h-24' />
				<h1 className='font-medium text-2xl'>{title}</h1>
				<p>{description}</p>
			</div>
		</>
	);
};

export default ExploreGridItem;
