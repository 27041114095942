/** @format */

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/nav';
import Home from './components/home';
import Rooms from './components/rooms';
import Hotel from './components/hotel';
import Footer from './components/footer';
import error from './assets/404.jpg';
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.css';
import Wellness from './components/wellness';
// import Testimonials from './components/utils/testimonial';
import SpecificRoom from './components/specificRoom';
import Attractions from './components/nearbyAttractions';
import Party from './components/parties';
import Family from './components/family';
import Meetings from './components/meetings';

function App() {
	return (
		<ParallaxProvider>
			<Router>
				<Nav />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/hotel' element={<Hotel />} />
					<Route path='/rooms' element={<Rooms />} />
					<Route path='/rooms/:roomType' element={<SpecificRoom />} />
					<Route path='/hotel/Things_To_Do' element={<Wellness />} />
					<Route path='/attractions' element={<Attractions />} />
					<Route path='/events' element={<Party />} />
					<Route path='/family' element={<Family />} />
					<Route path='/meetings' element={<Meetings />} />
					{/* <Route path='/test' element={<Testimonials />} /> */}
					<Route path='*' element={<NotFound />} />
				</Routes>
				{/* Footer */}
				<Footer />
			</Router>
		</ParallaxProvider>
	);
}
function NotFound() {
	return (
		<div className='flex flex-col items-center justify-center h-screen bg-gray-100'>
			<h1 className='text-4xl font-bold mb-4'>404 Not Found</h1>
			<p className='text-lg text-gray-600'>Oops! The page you're looking for doesn't exist.</p>
			<img className='mt-8 w-64' src={error} alt='Error' />
		</div>
	);
}
export default App;
