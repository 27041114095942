/** @format */

// import React, { useState } from 'react';
import React from 'react';
import Background from '../assets/hero.jpeg';
import Bar from '../assets/brownie-s-guesthouse-drinks.jpeg';
import Bar_1 from '../assets/bar_image_1.jpeg';
import Bar_2 from '../assets/bar_image_2.jpeg';
import Bar_3 from '../assets/bar_image_3.jpeg';
import Bar_4 from '../assets/bar_image_4.jpeg';
import Bar_5 from '../assets/bar_image_5.jpeg';
import Food from '../assets/brownie-s-guesthouse-food-presentation.jpeg';
import Restaurant_Chairs from '../assets/restaurant_chairs.jpeg';
import Room from '../assets/brownie-s-guesthouse-rooms.jpeg';
import Contact from '../assets/contact.png';
import Party from '../assets/brownies-party-host.jpeg';
import Family from '../assets/family-activities-nakuru-outing.png';
import Meetings from '../assets/end-of-year-meeting.png';
import Baboon_Cliff from '../assets/baboon-cliff-nakuru-attraction.jpeg';
import Section2 from '../assets/section2.jpeg';
import GridImage from './utils/gridImage';
import SectionTitle from './utils/sectionTitle';
import restaurantIcon from '../assets/restaurantIcon.png';
import BarIcon from '../assets/BarIcon.png';
import ParallaxComponent from './utils/parallax';
import { ImageSlider, TestimonialCarousel } from './utils/carousel';
import ScrollToTopButton from './utils/scrollToTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import AirbnbIcon from '../assets/airbnb_logo.png';
import ExpediaIcon from '../assets/expedia_logo.png';
import TripAdvisorIcon from '../assets/tripAdvisor_logo.png';

// import PopUp from '../assets/pop_up.png';
interface Gallery {
	id: number;
	image: string;
	alt: string;
}

// Define the gallery items for the 'standard' room
const restaurantItems: Gallery[] = [
	{
		id: 1,
		image: Food,
		alt: 'reastaurant',
	},
	{
		id: 2,
		image: Restaurant_Chairs,
		alt: 'reastaurant',
	},
];
// Define the gallery items for the 'standard' room
const barItems: Gallery[] = [
	{
		id: 1,
		image: Bar,
		alt: 'Bar',
	},
	{
		id: 2,
		image: Bar_1,
		alt: 'Bar_1',
	},
	{
		id: 3,
		image: Bar_2,
		alt: 'Bar_2',
	},
	{
		id: 4,
		image: Bar_3,
		alt: 'Bar_3',
	},
	{
		id: 5,
		image: Bar_4,
		alt: 'Bar 4',
	},
	{
		id: 6,
		image: Bar_5,
		alt: 'Bar 5',
	},
	// Add more images for the standard room as needed
];
const Home: React.FC = () => {
	// const [formData, setFormData] = useState({
	// 	name: '',
	// 	company: '',
	// 	email: '',
	// 	phone: '',
	// 	message: '',
	// });

	// const handleFormSubmit = async (event: any) => {
	// 	event.preventDefault();

	// 	try {
	// 		const response = await fetch('http://localhost:4000/contact', {
	// 			// const response = await fetch('https://brownies-backend.onrender.com/contact', {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},
	// 			body: JSON.stringify(formData),
	// 		});
	// 		if (response.ok) {
	// 			console.log('Form submitted successfully');
	// 			// Clear the form
	// 			setFormData({ email: '', phone: '', company: '', name: '', message: '' });
	// 		} else {
	// 			console.error('Form submission failed');
	// 		}
	// 	} catch (error) {
	// 		console.error('Error submitting form:', error);
	// 	}
	// };
	// const handleChange = (event: any) => {
	// 	setFormData({
	// 		...formData,
	// 		[event.target.id]: event.target.value,
	// 	});
	// };
	// const [showOverlay, setShowOverlay] = useState(true);

	// const handleCloseOverlay = () => {
	// 	setShowOverlay(false);
	// };
	return (
		<div className='font-sans'>
			{/* {showOverlay && (
				<div className='fixed inset-0 z-50 bg-black/50 flex items-center justify-center backdrop-blur-md backdrop-filter bg-scroll '>
					<div className='bg-white p-4 rounded-md text-center backdrop-blur-sm backdrop-filter'>
						<img src={`${PopUp}`} alt='Overlay' className='w-full h-auto max-w-sm mb-4 pt-6' />
						<button className=' absolute top-0 right-0 m-4 btn btn-primary' onClick={handleCloseOverlay}>
							<svg className='w-6 h-6 text-black' viewBox='0 0 20 20' fill='currentColor'>
								<path
									fillRule='evenodd'
									d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10L4.293 5.707z'
									clipRule='evenodd'
								/>
							</svg>
						</button>
					</div>
				</div>
			)} */}
			<div
				className='h-[90vh]  relative z-0 top-0 pt-40 pb-5 '
				style={{
					backgroundImage: `url(${Background})`, // Set the background image
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
				}}>
				{/* Top of page */}
				<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
				<section className='flex '>
					<div className='mx-auto my-[20vh] font-bold text-white w-[800px] relative z-20 text-center'>
						<h1 className='lg:text-[4rem] text-[2rem] '>
							Experience home <br /> away from home
						</h1>
						<p> From when you arrive to when you leave, Brownies is your home, not just a hotel</p>
					</div>
				</section>
			</div>

			{/* Section 2 */}
			<section className='grid grid-cols-1 lg:grid-cols-2 md:gap-10 gap-5 py-20 bg-section2 px-10 md:px-20'>
				<div className='w-full h-full lg:min-h-[683px]'>
					<img src={Contact} alt='Section 2' className='h-full w-full lg:object-cover' />
				</div>
				<div className='my-auto space-y-5'>
					{/* <h1 className='lg:text-[5rem] font-medium text-center'> */}
					<h1 className=' lg:text-7xl md:text-4xl text-2xl  font-medium text-center'>
						Fully experience <br /> Brownie's Deluxe
					</h1>
					<p className=' lg:min-w-80 mx-auto'>
						At Brownie's, we are dedicated to making sure that nothing comes in the way of you enjoying your travels. We believe that any hotel worth its name should focus fastidiously on
						getting the basics right, so that at the end of your trip, whether on business or for leisure, you should feel like you have arrived in your own home.
						<br /> <br /> We try extremely hard to get the most important things to you right….we promise that your bed will be so comfortable, you'll have trouble getting out of it in the
						morning; we promise that your shower will be so enjoyable you might be a few minutes late for your morning or evening engagement; we promise to delight you with our breakfast,
						lunch and dinner offerings including the beverage options available to you. <br /> <br /> Most of all, we promise that everyone at Brownie's will treat you like you are the
						only guest we have ever had!
					</p>
					<a href='/rooms'>
						<div className='px-3 py-2 bg-button hover:bg-button_active text-white w-fit mx-auto my-5'>Learn more </div>
					</a>
				</div>
			</section>
			{/* Section 3 */}
			<section className='my-20 flex flex-col mx-10 md:mx-20'>
				<SectionTitle heading='Our Offers' subheading={`CHOOSE WHAT'S BEST FOR YOU`} />
				<div className='grid lg:grid-cols-3 grid-cols-1 gap-1 md:gap-0 shadow-md'>
					<a href='/rooms'>
						<GridImage
							imageSrc={Room}
							title='Rooms'
							description='Explore unparalleled comfort and luxury in our diverse range of meticulously designed rooms, each offering a unique blend of style, modern amenities, and breathtaking views. '
						/>
					</a>
					<a href='/attractions'>
						<GridImage
							imageSrc={Baboon_Cliff}
							title='Nearby Attractions'
							description='Discover an abundance of excitement just steps away from our hotel. Immerse yourself in the vibrant local culture as you explore nearby attractions, from iconic landmarks to hidden gems. '
						/>
					</a>
					<a href='/events'>
						<GridImage
							imageSrc={Party}
							title='Events'
							description="Elevate your celebration experience with our exquisite party venues that promise an unforgettable ambiance and exceptional service. Whether it's a wedding reception, a corporate event, or a special occasion, our dedicated team ensures every detail is perfect."
						/>
					</a>
					<a href='/family'>
						<GridImage
							imageSrc={Family}
							title='Family/ Kid friendly'
							description='Indulge in a family-friendly escape where both adults and children can create lasting memories. Our hotel offers a range of amenities and activities suitable for all ages, ensuring that families can relax, have fun, and enjoy quality time together.'
						/>
					</a>
					<a href='/meetings'>
						<GridImage imageSrc={Meetings} title='Meetings' description='Discover all the ways we can facilitate your companies meetings from company retreats to board meetings.' />
					</a>
					<div className='flex h-full bg-white'>
						<div className='flex flex-col p-10 my-auto space-y-5 text-center mx-auto'>
							<h1 className='text-2xl'>You didn't find anything that suits your needs?</h1>
							<a href='/rooms' className='hover:underline'>
								See all of our rooms
							</a>
						</div>
					</div>
				</div>
			</section>
			{/* Section 4 */}
			<section className='my-20 flex flex-col mx-10 md:mx-[138px] space-y-5'>
				<SectionTitle heading='Restaurant' subheading={`FULLY EXPERIENCE THE LOCAL LIVING BY EATING LIKE A LOCAL`} />
				<div className=' grid lg:grid-cols-2 gap-10 lg:gap-16 grid-cols-1 '>
					<div className=' lg:w-fit w-full text-center space-y-5 mx-auto my-auto'>
						<img src={restaurantIcon} alt='Restaurant icon' className='w-42 h-32 mx-auto' />
						<h1 className='text-2xl '>Exceptional Food</h1>
						<p className='max-w-[400px] mx-auto'>
							Our well-equipped kitchen provides food services at your request throughout the day. Our menu is designed to offer you an ample selection of grills, vegetable dishes and
							stir frys. We focus on delivering always, freshness, tastiness and responsiveness to your needs and wants. Our staff is always eager to go the extra mile to fulfil your
							every demand, from an extra side dish, special dessert or beverage.
						</p>
					</div>
					<div className=' w-full h-full md:min-h-[450px] '>
						{/* <img src={Food} alt='Restautrant ' className='w-full h-full ' /> */}
						<ImageSlider sliderItems={restaurantItems} />
					</div>

					<div className='hidden md:block w-full h-full md:min-h-[450px] '>
						{/* <img src={Bar} alt='Restautrant' className='w-full h-full' /> */}
						<ImageSlider sliderItems={barItems} />
					</div>
					<div className='lg:w-fit w-full text-center space-y-5 mx-auto my-auto'>
						<img src={BarIcon} alt='Bar icon' className='md:w-42 h-32 mx-auto' />
						<h1 className='text-2xl '>Bar Drinks</h1>
						<p className='max-w-[400px] mx-auto'>We also offer a wide selection of alcoholic and non-alcoholic beverages including wines, spirits, beers and more.</p>
					</div>
					<div className='block md:hidden w-full h-full md:min-h-[450px] '>
						{/* <img src={Bar} alt='Restautrant' className='w-full h-full' /> */}
						<ImageSlider sliderItems={barItems} />
					</div>
				</div>
			</section>
			{/* Section 5  Parallax */}
			<section className='my-20 flex flex-col space-y-5'>
				<ParallaxComponent />
			</section>
			{/* Section 6 Guest Book */}
			<section className='my-20 md:mx-20 mx-10'>
				<SectionTitle heading='Guest Book' subheading={`READ HOW OTHERS FELT STAYING AT OUR HOTEL`} />

				<TestimonialCarousel />
			</section>

			{/* Trusted Reviews from our Guests */}
			<section className='my-20 md:mx-20 mx-5'>
				<SectionTitle heading='Trusted Reviews From Our Guests' subheading='Click the links below to see what people said' textSize='text-3xl' />

				<div className='flex items-center justify-center'>
					<a href='https://www.airbnb.co.uk/rooms/802459940686873374?guests=1&adults=1&s=67&unique_share_id=7300d96f-f109-4862-b753-4f34359d2d15' target='blank'>
						<img src={AirbnbIcon} alt='social proof link' className='w-auto md:h-14 h-auto' />
					</a>
					<a href='https://www.expedia.com/Nakuru-Hotels-Brownies-Guest-House.h37336831.Hotel-Information?recommendations-overlay=recommendations-overlay' target='blank'>
						<img src={ExpediaIcon} alt='social proof link' className='w-auto md:h-14 h-auto' />
					</a>
					<a href='https://www.tripadvisor.com/Hotel_Review-g1015946-d7216756-Reviews-Brownie_s_The_Haven-Nakuru_Rift_Valley_Province.html' target='blank'>
						<img src={TripAdvisorIcon} alt='social proof link' className='w-auto md:h-14 h-auto' />
					</a>
				</div>
			</section>
			{/* Map */}
			<section className='my-10'>
				<div className=' w-full h-[350px]'>
					<iframe
						title='Brownies'
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7494514909913!2d36.169954975284234!3d-0.3366457353261465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182990d4765c244f%3A0xfb00e882aeb99b08!2sBrownie&#39;s%20Guesthouse!5e0!3m2!1sen!2ske!4v1701593321355!5m2!1sen!2ske'
						style={{ border: 0 }}
						allowFullScreen={false}
						loading='lazy'
						className='w-full h-full'></iframe>
				</div>
			</section>
			{/* Contact Us */}
			<section className='grid grid-cols-1 md:grid-cols-2 gap-10 my-10 md:mx-20 mx-5'>
				{/* Image with Overlay */}
				<div className='relative'>
					<img src={Section2} alt='Contact_Us_Image' className='w-full h-full object-cover' />
					<div className='absolute inset-0 bg-black opacity-50'></div>
					<div className='absolute inset-0 flex items-center justify-center'>
						<h2 className='text-4xl font-bold text-white'>Contact Us</h2>
					</div>
				</div>

				{/* Contact Form */}
				{/* <form method='post' onSubmit={handleFormSubmit} id='Contact_form'>
					<h1 className='text-center font-medium text-2xl'>Send us a message and we’ll contact you as soon as possible:</h1>
					<div className='grid grid-cols-2 gap-4 p-6'>
						<div className='col-span-2 md:col-span-1 mb-4'>
							<label htmlFor='name' className='text-gray-700'>
								Name
							</label>
							<input
								type='text'
								id='name'
								onChange={handleChange}
								value={formData.name}
								className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500'
								placeholder='Enter Your Name'
								required
							/>
						</div>
						<div className='col-span-2 md:col-span-1 mb-4'>
							<label htmlFor='company' className='text-gray-700'>
								Company
							</label>
							<input
								type='text'
								id='company'
								onChange={handleChange}
								value={formData.company}
								className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500'
								placeholder='Enter Your Company'
								required
							/>
						</div>
						<div className='col-span-2 md:col-span-1 mb-4'>
							<label htmlFor='email' className='text-gray-700'>
								Email: email@example.com
							</label>
							<input
								type='email'
								id='email'
								onChange={handleChange}
								value={formData.email}
								className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500'
								placeholder='Enter Your Email'
								required
							/>
						</div>
						<div className=' col-span-2 md:col-span-1 mb-4'>
							<label htmlFor='phone' className='text-gray-700'>
								Phone (+254 712345678)
							</label>
							<input
								type='text'
								id='phone'
								onChange={handleChange}
								value={formData.phone}
								className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500'
								placeholder='Enter Your Phone Number'
								required
							/>
						</div>
						<div className='col-span-2 mb-4'>
							<label htmlFor='message' className='text-gray-700'>
								Message
							</label>
							<textarea
								id='message'
								onChange={handleChange}
								value={formData.message}
								className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-green-500'
								placeholder='Enter Your Message'
								required></textarea>
						</div>
						<div className='col-span-2'>
							<button className='bg-button hover:bg-button_active text-white px-4 py-2  transition duration-300 w-full' type='submit'>
								Submit
							</button>
						</div>
					</div>
				</form> */}
				<div className='flex h-full'>
					<div className='mx-auto my-auto grid grid-cols-1 gap-5'>
						<div className='text-sm md:text-lg  space-y-5 bg-button text-white p-5'>
							<h1 className='text-center font-medium text-2xl'>Contact Information</h1>
							<div className='flex flex-col'>
								<div className='flex space-x-2'>
									<div className='my-auto text-Secondary_Yellow'>
										<FontAwesomeIcon icon={faPhoneFlip} />
									</div>
									<a href='tel:+254700073455' className='hover:cursor-pointer hover:underline'>
										+254 700073455,
									</a>
								</div>

								<p className='px-6'>
									<a href='tel:+254727134200' className='hover:cursor-pointer hover:underline'>
										+254 727134200,
									</a>
									<br />
									<a href='tel:+254717968500' className='hover:cursor-pointer hover:underline'>
										+254 717968500
									</a>
								</p>
							</div>
							<div className='flex space-x-2'>
								<div className='my-auto text-Secondary_Yellow'>
									<FontAwesomeIcon icon={faEnvelope} />
								</div>
								<a href='mailto:Browniesdeluxe854@gmail.com' className='hover:underline'>
									Browniesdeluxe854@gmail.com
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ScrollToTopButton />
		</div>
	);
};

export default Home;
