/** @format */

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Person1 from '../../assets/4.png';
import Person2 from '../../assets/5.png';
import Person3 from '../../assets/6.png';

interface Testimonial {
	id: number;
	name: string;
	position: string;
	image: string;
	content: string;
}

const testimonials: Testimonial[] = [
	{
		id: 1,
		name: 'Mary',
		position: 'Ceo At BrandName',
		image: Person1,
		content:
			"Brownie's doesn't disappoint on the two things I look for in a hotel room - very comfortable bed, great shower at the right temperature and pressure. The garden is green and would be ideal for an intimate occasion. The swimming pool is open to the public.",
	},
	{
		id: 2,
		name: 'Jada',
		position: 'Ceo At BrandName',
		image: Person2,
		content:
			'Located near Greensteds school, Brownies Guest house has almost a mix of city and rural blends. About 10 mins to Nakuru town makes the location ideal for one to stay and still get a taste of the Nakuru life. ',
	},
	{
		id: 3,
		name: 'Eric',
		position: 'Ceo At BrandName',
		image: Person3,
		content:
			'Eric and Sharon went out of the way to make our stay extremely comfortable and and super private. We had taken the whole house so was like a home away from home. Delicious Kenyan food with fresh ingredients and full of remarkable flavour. We will definitely be going back!',
	},
];

const TestimonialCarousel: React.FC = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	return (
		<Slider {...settings}>
			{testimonials.map((testimonial) => (
				<div key={testimonial.id}>
					<div className='grid md:grid-cols-2 grid-cols-1 gap-5 shadow-md'>
						<div className='w-full  h-full overflow-hidden'>
							<img src={testimonial.image} alt={testimonial.name} className='w-full h-full object-cover' />
						</div>
						<div className='p-4 my-auto space-y-2'>
							<h3 className='text-lg font-bold'>{testimonial.name}</h3>
							{/* <p className='text-gray-500'>{testimonial.position}</p> */}
							<p className='text-gray-800'>{testimonial.content}</p>
						</div>
					</div>
				</div>
			))}
		</Slider>
	);
};

const NextArrow: React.FC = (props) => (
	<div {...props} className='absolute -bottom-10 right-2 border-2 border-solid border-white cursor-pointer p-2 bg-button  hover:bg-button_active transition duration-300'>
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='white' className='h-6 w-6'>
			<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9 5l7 7-7 7'></path>
		</svg>
	</div>
);

const PrevArrow: React.FC = (props) => (
	<div {...props} className='absolute -bottom-10 right-12 border-2 border-solid border-white cursor-pointer p-2 bg-button  hover:bg-button_active transition duration-300'>
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='white' className='h-6 w-6'>
			<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 19l-7-7 7-7'></path>
		</svg>
	</div>
);

interface Gallery {
	id: number;
	image: string;
	alt: string;
}
interface GalleryCarouselProps {
	galleryItems: Gallery[];
}
const GalleryCarousel: React.FC<GalleryCarouselProps> = ({ galleryItems }) => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <GalleryNextArrow />,
		prevArrow: <GalleryPrevArrow />,
	};

	return (
		<div className='w-full h-fit bg-slate-900'>
			<Slider {...settings}>
				{galleryItems.map((image) => (
					<div key={image.id} className='p-5 h-full'>
						<div className='md:w-1/2 w-full h-full overflow-hidden mx-auto flex items-center justify-center'>
							<img src={image.image} alt={image.alt} className='  w-[300px] h-[300px] md:w-full md:h-[500px] object-fit md:object-contain ' />
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
};
const GalleryNextArrow: React.FC = (props) => (
	<div {...props} className='absolute bottom-1/2 right-0  cursor-pointer p-2 bg-white  hover:bg-button transition duration-300'>
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='black' className='h-6 w-6'>
			<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9 5l7 7-7 7'></path>
		</svg>
	</div>
);

const GalleryPrevArrow: React.FC = (props) => (
	<div {...props} className='absolute bottom-1/2 left-0  cursor-pointer p-2 bg-white  hover:bg-button transition duration-300 z-20'>
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='black' className='h-6 w-6'>
			<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 19l-7-7 7-7'></path>
		</svg>
	</div>
);

interface imageSlider {
	id: number;
	image: string;
	alt: string;
}
interface imageSliderProps {
	sliderItems: imageSlider[];
}
const ImageSlider: React.FC<imageSliderProps> = ({ sliderItems }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<div className=' w-full '>
			<Slider {...settings}>
				{sliderItems.map((image) => (
					<div key={image.id} className='p-5 h-full'>
						{/* <div className='md:w-1/2 w-full h-full overflow-hidden mx-auto flex items-center justify-center'> */}
						<img src={image.image} alt={image.alt} className='  sm:h-[500px] object-fit ' />
						{/* </div> */}
					</div>
				))}
			</Slider>
		</div>
	);
};
// const TestimonialsSection: React.FC = () => {
// 	return <TestimonialCarousel />;
// };

export { TestimonialCarousel, GalleryCarousel, ImageSlider };
