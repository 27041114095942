/** @format */

import SectionTitle from './utils/sectionTitle';
import Background from '../assets/hero.jpeg';
import ScrollToTopButton from './utils/scrollToTop';
import parties from '../assets/parties.png';
import birthday from '../assets/birthday.png';
import birthdayPic from '../assets/Birthday_Pic.png';
import party from '../assets/brownies-party-host.jpeg';
import confetti from '../assets/confetti.png';

const Party: React.FC = () => {
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[40vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex '>
						<div className='mx-auto  font-bold text-white w-[400px] relative z-20 text-center'>
							<SectionTitle heading='Events' subheading='Below is a list of events we can facilitate' />
						</div>
					</section>
				</div>
				{/* Spa */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='' subheading='Baboon Cliff located in the iconic Lake Nakuru National Park' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20'>
						{/* The City */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={confetti} alt='cliff icon' className='w-18 h-16 mx-auto' />
							<h1 className='text-2xl '>Parties</h1>
							<p className='max-w-[400px] mx-auto'>
								Depending on weather conditions, we can normally serve meals on the patio or garden. We also offer an ideal location for group functions or events and would be
								delighted to host your function. On the weekends, a leisurely breakfast spread is served from 7am to 11am each day, while during holiday seasons such as Christmas and
								Easter, we will offer even more temptations.
							</p>
						</div>
						<div className=' w-full h-full md:max-h-[700px] max-h-[450px]'>
							<img src={parties} alt='Spa' className='w-full h-full object-contain' />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Gym */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Golf' subheading='In close proximity to one of the best Golf courses in Kenya' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* cycling */}
						<div className=' hidden md:block  w-full h-full max-h-[700px]'>
							<img src={birthdayPic} alt='Cycling' className='w-full h-full object-contain' />
						</div>
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={birthday} alt='cycling icon' className='w-24 h-20 mx-auto' />
							<h1 className='text-2xl '>Birthdays</h1>
							<p className='max-w-[400px] mx-auto'>
								Celebrate Memorable Birthdays with Us: Elevate your special day with a birthday bash at our exquisite hotel. Our dedicated event spaces provide the perfect backdrop for
								a memorable celebration. Whether it's an intimate gathering or a lively party, our professional team is here to turn your vision into reality. Indulge in delectable
								culinary delights tailored to your preferences, complemented by impeccable service. From personalized decorations to a welcoming ambiance, we ensure every detail
								exceeds your expectations.
							</p>
						</div>
						<div className=' block md:hidden w-full h-full max-h-[450px]'>
							<img src={birthdayPic} alt='Cycling' className='w-full h-full object-cover' />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Gym */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Lakes' subheading='In the middle of two lakes.' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* cycling */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={confetti} alt='cycling icon' className='w-18 h-16 mx-auto' />
							<h1 className='text-2xl '>Friends Getaway</h1>
							<p className='max-w-[400px] mx-auto'>
								Make your escape with friends an unforgettable adventure at our exceptional hotel, where entertainment knows no bounds. Engage in friendly competition with a game of
								pool, dive into relaxation with a refreshing swim, and indulge in laughter-filled moments with a selection of board games. Our versatile spaces cater to every aspect of
								your getaway, offering a perfect blend of leisure and excitement.
							</p>
						</div>
						<div className=' w-full h-full max-h-[450px]'>
							<img src={party} alt='Cycling' className='w-full h-full object-cover' />
						</div>
						{/* End */}
					</div>
				</section>
			</div>
			<ScrollToTopButton />
		</div>
	);
};

export default Party;
