/** @format */

import React from 'react';
import Background from '../assets/hero.jpeg';
import SectionTitle from './utils/sectionTitle';
import game from '../assets/gameRoom.png';
import cozy from '../assets/cozy.png';
import smallBar from '../assets/smallBar.png';
import smallRestaurant from '../assets/smallRestaurant.png';
import GridImage from './utils/gridImage';
import ScrollToTopButton from './utils/scrollToTop';
import SunriseRoom from '../assets/sunrise.png';
import SunsetRoom from '../assets/sunset.png';
import Standard from '../assets/brownie-s-guesthouse-rooms.jpeg';
import Deluxe from '../assets/deluxe.png';

const Rooms: React.FC = () => {
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[90vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex '>
						<div className='mx-auto my-[20vh] font-bold text-white w-[700px] relative z-20 text-center'>
							<h1 className='md:text-[4rem] text-[2rem] '>Cozy. Quiet. Cool.</h1>
							<p> Book Now</p>
						</div>
					</section>
				</div>
				{/* Service & Amenities */}
				<section className=' mx-5 my-20'>
					<SectionTitle heading='Service & Amenities' subheading={`THE PALACE EXPERIENCE`} />
					{/* Grids */}
					<div className='grid lg:grid-cols-2 grid-cols-1 gap-5 lg:gap-0'>
						<div
							className='w-full h-full p-10 md:p-20 lg:min-h-[500px]'
							style={{
								backgroundImage: `url(${SunriseRoom})`, // Set the background image
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
							}}>
							<div className='my-auto mx-auto p-10 m-10 bg-white space-y-10'>
								<div className='text-center'>
									<h1 className='text-2xl font-bold'>Sunrise Room</h1>
								</div>
								<div className='space-y-5'>
									<p>
										These are two rooms in the east wing of the main house, into which the rays of the rising sun cast their early light in the morning. They are also the largest
										of all the rooms at Brownie’s and are ideal for double and triple occupancy where needed.
									</p>
									<ul className='list-disc'>
										<li>The larger of the two rooms has a king-size bed.</li>
										<li>42-inch satellite TV screen,</li>
										<li>Sitting area and a large bathroom with shower.</li>
										<li>Features: King-size bed, sitting area and a large bathroom with shower.</li>
										<li>Occupancy: Double or Triple where needed</li>
									</ul>
								</div>
							</div>
						</div>
						<div
							className='w-full h-full p-10 md:p-20'
							style={{
								backgroundImage: `url(${SunsetRoom})`, // Set the background image
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
							}}>
							<div className='my-auto mx-auto p-10 m-10 bg-white space-y-10'>
								<div className='text-center'>
									<h1 className='text-2xl font-bold'>Sunset Rooms</h1>
								</div>
								<div className='space-y-5'>
									<p>
										These are three rooms in the west wing of the main house, into which the rays of the setting sun cast their fading light in the late afternoons and early
										evenings.
									</p>
									<ul className='list-disc'>
										<li>Each room is en-suite with shower bathrooms, tastefully furnished with queen size beds, closets and work stations</li>
										<li>Double or Triple occupancy where needed</li>
										<li>32 inch TV screens,outhouse rooms for entertainment</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Restaurant and Bar */}
				<section className=' my-20 lg:mx-20 mx-5'>
					{/* <div className=' flex flex-col md:flex-row mx-auto md:space-x-20 lg:space-x-40 md:justify-center'> */}
					<div className=' grid md:grid-cols-4 grid-cols-1 gap-20 mx-auto'>
						<div className=' mx-auto flex flex-col p-5 space-y-5'>
							<img src={cozy} alt='cozy' className='w-20 h-24 mx-auto' />
							<p className='font-bold text-xl mx-auto'>Cozy Rooms</p>
						</div>
						<div className='mx-auto flex flex-col p-5 space-y-5'>
							<img src={smallRestaurant} alt='cozy' className='w-20 h-24 mx-auto' />
							<p className='font-bold text-xl mx-auto'>Exceptional Food</p>
						</div>
						<div className=' flex flex-col p-5 space-y-5'>
							<img src={game} alt='cozy' className='w-20 h-24 mx-auto' />
							<p className='font-bold text-xl mx-auto'>Game Room</p>
						</div>
						<div className=' flex flex-col p-5 space-y-5 '>
							<img src={smallBar} alt='cozy' className='w-20 h-24 mx-auto' />
							<p className='font-bold text-xl mx-auto'>Bar Drinks</p>
						</div>
					</div>
				</section>
				{/* Rooms */}
				{/* Section 3 */}
				<section className='my-20 flex flex-col md:mx-20 mx-5'>
					<div className='grid md:grid-cols-2 grid-cols-1 gap-1 md:gap-0'>
						<a href='/rooms/standard'>
							<GridImage
								imageSrc={Standard}
								title='Standard'
								subTitle='TWIN ROOM'
								description='With a generously sized marble bathroom and floor-to-ceiling windows offering views of the city, our premier guest rooms are a comfortable home away from home'
							/>
						</a>
						<a href='/rooms/deluxe'>
							<GridImage
								imageSrc={Deluxe}
								title='Deluxe'
								subTitle='FAMILY ROOM'
								description='With a generously sized marble bathroom and floor-to-ceiling windows offering views of the city, our premier guest rooms are a comfortable home away from home'
							/>
						</a>
						{/* <a href='/rooms/super-deluxe'>
							<GridImage
								imageSrc={Background}
								title='Super Deluxe'
								subTitle='PREMIUM ROOM'
								description='With a generously sized marble bathroom and floor-to-ceiling windows offering views of the city, our premier guest rooms are a comfortable home away from home.'
							/>
						</a>
						<a href='/rooms/superior'>
							<GridImage
								imageSrc={Background}
								title='Superior'
								subTitle='CLASSIC APARTMENT'
								description='With a generously sized marble bathroom and floor-to-ceiling windows offering views of the city, our premier guest rooms are a comfortable home away from home.'
							/>
						</a> */}
					</div>
				</section>
			</div>
			<ScrollToTopButton />
		</div>
	);
};

export default Rooms;
