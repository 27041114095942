/** @format */

import { ParallaxBanner } from 'react-scroll-parallax';
import Background from '../../assets/parallax_replacement.jpeg';
import Play from '../../assets/playIcon.png';

// const Image = () => {
// 	const parallaxController = useParallaxController();
// 	// Ensure parallaxController exists before calling its methods
// 	const handleLoad = () => {
// 		if (parallaxController) {
// 			parallaxController.update();
// 		}
// 	};
// 	return <img src={Background} onLoad={handleLoad} alt='Parallax Image' />;
// };
const ParallaxComponent = () => {
	// return <div ref={ref} />;
	return (
		<>
			<ParallaxBanner layers={[{ image: Background, speed: -15 }]} style={{ aspectRatio: '3 / 1' }}>
				<div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50'>
					<div className='flex flex-col items-center'>
						<a href='https://www.youtube.com/watch?v=kdOsKeI11y8'>
							<img src={Play} alt='play icon' className='md:w-40 md:h-32 h-16 w-20' />
						</a>
						<h1 className='lg:text-7xl md:text-4xl text-2xl font-medium text-white text-center'>
							See the experience <br />
							before you feel it.
						</h1>
					</div>
				</div>
			</ParallaxBanner>
			{/* <Image /> */}
		</>
	);
};

export default ParallaxComponent;
