/** @format */
import React from 'react';
import Background from '../assets/hero.jpeg';
import SectionTitle from './utils/sectionTitle';
import pool from '../assets/pool.png';
import ScrollToTopButton from './utils/scrollToTop';
import swimming from '../assets/hotels-with-swimming-pools-nakuru.jpeg';
import poolTable from '../assets/poolTable.png';
import gameIcon from '../assets/game_Grey.png';
import walking from '../assets/walk.png';
import { ImageSlider } from './utils/carousel';
import pool_2 from '../assets/pool_2.jpg';
import pool_1 from '../assets/pool_1.jpeg';
import pool_3 from '../assets/pool_2.jpeg';
import pool_4 from '../assets/pool_3.jpeg';
import poolTable_1 from '../assets/poolTable_1.jpeg';
import poolTable_2 from '../assets/poolTable_2.jpeg';
import poolTable_3 from '../assets/poolTable_3.jpeg';

interface Gallery {
	id: number;
	image: string;
	alt: string;
}

// Define the gallery items for the 'standard' room
const poolItems: Gallery[] = [
	{
		id: 1,
		image: pool_1,
		alt: 'pool',
	},
	{
		id: 2,
		image: pool_2,
		alt: 'pool',
	},
	{
		id: 3,
		image: pool_3,
		alt: 'pool',
	},
	{
		id: 4,
		image: pool_4,
		alt: 'pool',
	},
	{
		id: 4,
		image: swimming,
		alt: 'pool',
	},
];
const poolTableItems: Gallery[] = [
	{
		id: 1,
		image: poolTable_3,
		alt: 'pool',
	},
	{
		id: 2,
		image: poolTable_1,
		alt: 'pool',
	},
	{
		id: 3,
		image: poolTable_2,
		alt: 'pool',
	},
	{
		id: 4,
		image: poolTable,
		alt: 'pool',
	},
];
const Wellness: React.FC = () => {
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[40vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex '>
						<div className='mx-auto  font-bold text-white w-[400px] relative z-20 text-center'>
							<SectionTitle heading='Things To Do' subheading='Some Guest Favourites' />
						</div>
					</section>
				</div>
				{/* Spa */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Spa' subheading='EVERY TREATMENT IS SPECIFICALLY DESIGNED TO OFFER A UNIQUE EXPERIENCE' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20'>
						{/* The City */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={pool} alt='spa icon' className='w-42 h-32 mx-auto' />
							<h1 className='text-2xl '>Swimming Pool</h1>
							<p className='max-w-[400px] mx-auto'>
								Immerse yourself in the refreshing embrace of our inviting swimming pool. Whether you seek a leisurely dip or a playful splash, our pool is the perfect oasis for
								relaxation and enjoyment. Indulge in moments of aquatic bliss at our hotel's pool – your gateway to refreshing experiences
							</p>
						</div>
						<div className=' w-full h-full min-h-[450px]'>
							{/* <img src={swimming} alt='Spa' className='w-full h-full object-contain' /> */}
							<ImageSlider sliderItems={poolItems} />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Gym */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Gym Center' subheading='STRENGTH AND CONDITIONING, WEIGHTLIFTING, FRIENDLY ENVIRONMENT' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* cycling */}
						<div className=' hidden md:block w-full h-full max-h-[700px]'>
							{/* <img src={poolTable} alt='Cycling' className='w-full h-full object-contain' /> */}

							<ImageSlider sliderItems={poolTableItems} />
						</div>
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={gameIcon} alt='cycling icon' className='w-42 h-32 mx-auto' />
							<h1 className='text-2xl '>Pool Table</h1>
							<p className='max-w-[400px] mx-auto'>
								Challenge your friends to a friendly game of pool at our dedicated pool table area. Engage in lively competition and create unforgettable moments of camaraderie, adding
								an extra layer of enjoyment.
							</p>
						</div>
						<div className='block md:hidden w-full h-full max-h-[450px]'>
							<ImageSlider sliderItems={poolTableItems} />
							{/* <img src={poolTable} alt='Cycling' className='w-full h-full object-contain' /> */}
						</div>
						{/* End */}
					</div>
				</section>
				{/* Pool */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Pool' subheading='WARM, PURE WATER' /> */}
					<div className=' grid md:grid-cols-2 md:gap-20 gap-10 grid-cols-1  my-20'>
						{/* The City */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={walking} alt='pool icon' className='w-28 h-26 mx-auto' />
							<h1 className='text-2xl '>Neighbourhood Walk</h1>
							<p className='max-w-[400px] mx-auto'>
								Start and end your day with refreshing walks in our hotel located within a secure gated community. Revel in the tranquility of morning and evening strolls, immersing
								yourself in the peaceful charm of our exclusive surrounding
							</p>
						</div>
						<div className=' w-full h-full min-h-[450px]'>
							<img src={Background} alt='pool ' className='w-full h-full object-contain' />
						</div>
						{/* End */}
					</div>
				</section>
			</div>
			<ScrollToTopButton />
		</div>
	);
};

export default Wellness;
