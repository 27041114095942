/** @format */

import React from 'react';
import Logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';

const Footer: React.FC = () => {
	return (
		<footer className='bg-[#cc895e] text-white font-sans'>
			<div className='py-10 lg:py-20'>
				{/* Top Level */}
				<div className='flex flex-col space-y-10 md:space-y-0 md:flex-row justify-start md:justify-between items-center mb-6 md:mx-20 mx-10 pb-10'>
					<div className='logo'>
						<img src={Logo} alt='Logo' className='w-fit h-16 lg:h-20 lg:w-[15rem]' />
					</div>
					<div className='social-icons space-x-5 flex'>
						<a href='https://www.facebook.com/browniesguesthouse/' className='footer-icon-link w-fit'>
							<FontAwesomeIcon icon={faFacebookSquare} className='w-8 h-8 hover:bg-white  transition duration-500 ease-in-out hover:text-black p-3' />
						</a>
						<a href='https://instagram.com/brownies.guesthouseke?igshid=OGQ5ZDc2ODk2ZA==' className='footer-icon-link  '>
							<FontAwesomeIcon icon={faInstagram} className='w-8 h-8 hover:bg-white  transition duration-500 ease-in-out hover:text-black p-3' />
						</a>
					</div>
				</div>

				{/* Line Separator */}
				<hr className='border-white' />

				{/* Second Level */}
				<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-6 md:mx-20 mx-10'>
					{/* Phone and Mail Info */}
					<div>
						<h4 className='text-lg md:text-2xl font-semibold mb-2 '>Phone and Mail</h4>
						<div className='text-sm md:text-lg  space-y-5'>
							<div className='flex space-x-2 font-bold'>
								<div className='my-auto text-Secondary_Yellow'>
									<FontAwesomeIcon icon={faPhoneFlip} />
								</div>
								<p className='text-lg'>
									<a href='tel:+254700073455' className='hover:cursor-pointer hover:underline'>
										+254 700073455,{' '}
									</a>
									<br />
									<a href='tel:+254727134200' className='hover:cursor-pointer hover:underline'>
										+254 727134200,
									</a>
									<br />
									<a href='tel:+254717968500' className='hover:cursor-pointer hover:underline'>
										+254 717968500
									</a>
								</p>
							</div>
							<div className='flex space-x-2 font-bold'>
								<div className='my-auto text-Secondary_Yellow'>
									<FontAwesomeIcon icon={faEnvelope} />
								</div>
								<a href='mailto:Browniesdeluxe854@gmail.com' className='hover:underline text-lg text-ellipsis overflow-hidden'>
									Browniesdeluxe854@gmail.com
								</a>
							</div>
						</div>
					</div>
					{/* Company Description */}
					<div>
						<h4 className='text-lg md:text-2xl font-semibold mb-2'>Reach us</h4>
						<p className='text-sm md:text-lg'>
							Lockwood Estate, Greensteds Area <br /> Off Nairobi Nakuru Highway,
							<br /> Nakuru County, Kenya
						</p>
					</div>

					{/* Check-in hours */}
					<div>
						<h4 className='text-lg md:text-2xl font-semibold mb-2'>Check-in hours</h4>
						<p className='text-sm md:text-lg'>Very flexible check in and check out hours</p>
					</div>

					{/* Subscribe */}
					<div>
						<h4 className='text-lg md:text-2xl font-semibold mb-2'>Subscribe to know the latest deals!</h4>
						<div className='flex flex-col items-center md:flex-row md:items-start md:space-x-4'>
							{/* Subscribe Input */}
							<div className='flex-1 mb-4 md:mb-0'>
								<label htmlFor='email' className='sr-only'>
									Email Address
								</label>
								<input
									type='email'
									id='email'
									placeholder='Enter your email'
									className='w-full px-4 py-2 border-2 border-white bg-transparent focus:outline-none focus:border-button_active bg-white'
								/>
							</div>

							{/* Subscribe Button */}
							<button className='bg-button_active hover:bg-button_darker text-white px-4 py-2  transition duration-300'>Subscribe</button>
						</div>
					</div>
				</div>

				{/* Bottom Level */}
				<div className='text-center mt-8 mx-10'>
					<p>&copy; {new Date().getFullYear()} Brownies Guest House. All Rights Reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
