/** @format */

import React from 'react';
interface SectionTitleProps {
	heading: string;
	subheading: string;
	textSize?: string;
}
const SectionTitle: React.FC<SectionTitleProps> = ({ heading, subheading, textSize }) => {
	return (
		<div className='mx-auto text-center space-y-5 mb-10'>
			<h1 className={`${textSize ? textSize : 'text-4xl'}`}>{heading}</h1>
			<div className='bg-gray-500 w-8 h-[1px] mx-auto'></div>
			<p>{subheading}</p>
		</div>
	);
};
export default SectionTitle;
