/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
// import { Link } from 'react-router-dom';

const Nav: React.FC = () => {
	// State to control the mobile menu visibility
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const mobileMenuRef = useRef<HTMLButtonElement>(null);
	// Function to toggle mobile menu
	const toggleMobileMenu = () => {
		setMobileMenuOpen(!isMobileMenuOpen);
	};
	// const navBackground = isAtTop && !isMobileMenuOpen ? 'bg-opacity-50' : 'bg-gray-800';

	const closeMobileMenu = () => {
		setMobileMenuOpen(false);
	};

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target as Node)) {
				// Clicked outside the mobile menu, so close it
				closeMobileMenu();
			}
		};

		// Attach the event listener when the mobile menu is open
		if (isMobileMenuOpen) {
			document.addEventListener('click', handleOutsideClick);
		}

		// Clean up the event listener when the component unmounts or mobile menu closes
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [isMobileMenuOpen]);

	const location = useLocation();
	const currentPage = location.pathname;

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleDropdownToggle = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
	const handleSmallDropdownToggle = (event: any) => {
		event.stopPropagation(); // Stop the click event from propagating
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleDropdownClose = () => {
		setIsDropdownOpen(false);
	};
	return (
		<>
			<nav className='bg-white bg-opacity-0 hover:bg-opacity-100 top-0 z-10 absolute w-full py-2 hover:text-black text-white transition duration-500 ease-in-out font-sans'>
				<div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 lg:py-2'>
					<div className='relative flex h-16 items-center justify-between'>
						<div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
							{/* Mobile Menu button */}
							<div className='absolute inset-y-0 right-0 flex items-center space-x-4 sm:hidden '>
								{/* <!-- Mobile menu button--> */}
								<button
									type='button'
									className={`relative inline-flex items-center justify-center rounded-md p-2 ${
										isMobileMenuOpen ? 'text-black' : 'text-white bg-button'
									} hover:bg-button_active hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
									aria-controls='mobile-menu'
									aria-expanded={isMobileMenuOpen}
									onClick={toggleMobileMenu}
									ref={mobileMenuRef}>
									<span className='absolute -inset-0.5'></span>
									<span className='sr-only'>Open main menu</span>

									{/* Small menu Icon */}
									{!isMobileMenuOpen ? (
										<svg className='block h-6 w-6' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' aria-hidden='true'>
											<path stroke-linecap='round' stroke-linejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5' />
										</svg>
									) : (
										<svg className='block h-6 w-6' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' aria-hidden='true'>
											<path stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' />
										</svg>
									)}
								</button>
							</div>
							{/* Logo */}
							<div className='flex flex-shrink-0 items-center'>
								<a href='/'>
									<img className='lg:h-20 h-14 w-auto lg:w-[15rem]' src={logo} alt='Brownies' />
								</a>
							</div>
						</div>
						{/* Nav Links */}
						<div className='hidden sm:ml-6 sm:block'>
							<div className='flex space-x-4'>
								{/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
								<a href='/' className={`${currentPage === '/' ? 'border-b-2 border-button' : ''}  px-3 py-2 text-base font-bold`}>
									Home
								</a>
								{/* Our Hotel Dropdown */}
								<div className='relative group mt-[0.5rem]' onMouseEnter={handleDropdownToggle} onMouseLeave={handleDropdownClose}>
									<a
										href='/hotel'
										className={`${currentPage === '/hotel' || currentPage === '/hotel/Things_To_Do' ? 'border-b-2 border-button' : ''} px-3 py-2 text-base font-bold `}>
										Our Hotel
									</a>

									{/* Dropdown Content */}
									{isDropdownOpen && (
										<div className='absolute z-10 mt-2 w-32 space-y-2 bg-white border border-gray-200 divide-y divide-gray-100'>
											<a href='/hotel' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
												Our Hotel
											</a>
											<a href='/hotel/Things_To_Do' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
												Things To Do
											</a>
										</div>
									)}
								</div>
								<a href='/rooms' className={`${currentPage.includes('/rooms') ? ' border-b-2 border-button ' : ' '} px-3 py-2 text-base font-bold`}>
									Rooms
								</a>
								<a href='/events' className={`${currentPage.includes('/events') ? ' border-b-2 border-button ' : ' '} px-3 py-2 text-base font-bold`}>
									Events
								</a>
								<a href='/meetings' className={`${currentPage.includes('/meetings') ? ' border-b-2 border-button ' : ' '} px-3 py-2 text-base font-bold`}>
									Meetings
								</a>
							</div>
						</div>
					</div>
				</div>
				{/* Mobile menu */}
				{isMobileMenuOpen && (
					<div className='sm:hidden' id='mobile-menu'>
						<div className='space-y-1 px-2 pb-3 pt-2'>
							<a
								href='/'
								className={`${currentPage === '/' ? 'bg-button text-white' : 'text-black hover:bg-button_active hover:text-white'} block rounded-md px-3 py-2 text-base font-medium`}
								aria-current='page'>
								Home
							</a>
							{/* Our Hotel Dropdown in Mobile Menu */}
							<div className='relative group'>
								<span
									className={`${
										currentPage.includes('/hotel') ? 'bg-button_active text-white' : 'text-black hover:bg-button_active hover:text-white'
									} block rounded-md px-3 py-2 text-base font-medium`}
									onClick={(event) => handleSmallDropdownToggle(event)}>
									Our Hotel
								</span>
								{isDropdownOpen && (
									<div className='absolute z-10 mt-2 space-y-2 bg-white border border-gray-200 divide-y divide-gray-100'>
										<a href='/hotel' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
											Our Hotel
										</a>
										<a href='/hotel/Things_To_Do' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
											Things To Do
										</a>
									</div>
								)}
							</div>
							<a
								href='/rooms'
								className={`${
									currentPage.includes('/rooms') ? 'bg-button text-white' : 'text-black hover:bg-button_active hover:text-white'
								} block rounded-md px-3 py-2 text-base font-medium`}>
								Rooms
							</a>
							<a
								href='/events'
								className={`${
									currentPage.includes('/events') ? 'bg-button text-white' : 'text-black hover:bg-button_active hover:text-white'
								} block rounded-md px-3 py-2 text-base font-medium`}>
								Events
							</a>
							<a
								href='/meetings'
								className={`${
									currentPage.includes('/meetings') ? 'bg-button text-white' : 'text-black hover:bg-button_active hover:text-white'
								} block rounded-md px-3 py-2 text-base font-medium`}>
								Meetings
							</a>
						</div>
					</div>
				)}
			</nav>
		</>
	);
};

export default Nav;
