/** @format */
import SectionTitle from './utils/sectionTitle';
import Background from '../assets/hero.jpeg';
import cliff from '../assets/cliff.png';
import Lake from '../assets/lakes.png';
import LakeIcon from '../assets/lake_icon.png';
import Golf_Icon from '../assets/golf_1.png';
import ScrollToTopButton from './utils/scrollToTop';
import Baboon_Cliff from '../assets/baboon-cliff-nakuru-attraction.jpeg';
import Golf from '../assets/nakuru-golf-course-brownies-kenya.jpeg';
const Attractions: React.FC = () => {
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[40vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex '>
						<div className='mx-auto  font-bold text-white w-[400px] relative z-20 text-center'>
							<SectionTitle heading='Nearby Attractions' subheading='Some of our favourite nearby attractions' />
						</div>
					</section>
				</div>
				{/* Spa */}
				<section className='md:mx-20 mx-5 my-20'>
					<SectionTitle heading='Lake Nakuru National Park' subheading='Baboon Cliff located in the iconic Lake Nakuru National Park' />
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20'>
						{/* The City */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={cliff} alt='cliff icon' className='w-24 h-18 mx-auto' />
							<h1 className='text-2xl '>Baboon Cliff</h1>
							<p className='max-w-[400px] mx-auto'>
								Brownie’s is a short distance away from the picturesque, awe-inspiring Baboon Cliff, inside the Lake Nakuru National Park. Treat yourself to a day out picnic with your
								loved ones or friends. We will make you a picnic basket to take with you, though the baboons may want you to share the food with them!
							</p>
						</div>
						<div className='w-full h-full min-h-[450px]'>
							<img src={Baboon_Cliff} alt='Cliff' className='w-full h-full object-contain' />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Golf */}
				<section className='md:mx-20 mx-5 my-20'>
					<SectionTitle heading='Golf' subheading='In close proximity to one of the best Golf courses in Kenya' />
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* golf */}
						<div className=' hidden md:block  w-full h-full min-h-[450px]'>
							<img src={Golf} alt='Cycling' className='w-full h-full' />
						</div>
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={Golf_Icon} alt='cycling icon' className='w-24 h-18 mx-auto' />
							<h1 className='text-2xl '>Nakuru Golf Club</h1>
							<p className='max-w-[400px] mx-auto'>
								The 18-hole Nakuru Golf Club is one of the most scenic golf courses one will ever see, being set on the slopes of the Menengai Crater and overlooking the entire Nakuru
								town and its environs, as well as Lake Nakuru itself. According to 'top100golfcourses.co.uk', 'the spectacle from the 8th and 18th tees of blazing coral pink flamingos
								set against the deep blue colour of nearby Lake Nakuru is one of the finest wildlife sights in all of Africa’. We would be delighted to arrange a round or two of golf
								at this spectacular course at your request, and we keep a spare set of golf clubs for our guests' use.
							</p>
						</div>
						<div className=' bloc md:hidden w-full h-full min-h-[450px]'>
							<img src={Golf} alt='Cycling' className='w-full h-full object-contain' />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Lakes */}
				<section className='md:mx-20 mx-5 my-20'>
					<SectionTitle heading='Lakes' subheading='In the middle of two lakes.' />
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* Lakes */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={LakeIcon} alt='Lake icon' className='w-24 h-18 mx-auto' />
							<h1 className='text-2xl '>Lake Baringo and Lake Bogoria</h1>
							<p className='max-w-[400px] mx-auto'>
								Located in Baringo County, Lake Baringo and Lake Bogoria are both easily accessible from Brownie’s. These two lakes are increasingly popular tourist destinations, being
								home to hot springs and fumaroles and several important archaeological and paleontological sites. Day or overnight visits can be arranged on request.
							</p>
						</div>
						<div className='w-full h-full min-h-[450px]'>
							<img src={Lake} alt='Lake' className='w-full h-full object-contain' />
						</div>
						{/* End */}
					</div>
				</section>
			</div>
			<ScrollToTopButton />
		</div>
	);
};

export default Attractions;
