/** @format */

import React from 'react';
import './gridImage.css';

interface GridImageProps {
	imageSrc: string;
	title: string;
	description?: string;
	subTitle?: string;
}

const GridImage: React.FC<GridImageProps> = ({ imageSrc, title, description, subTitle }) => {
	// const [isHovered, setHovered] = useState(false);
	// const handleMouseEnter = () => {
	// 	setHovered(true);
	// };

	// const handleMouseLeave = () => {
	// 	setHovered(false);
	// };

	return (
		<div className='relative w-full h-full overflow-hidden group'>
			<img className='w-full h-full object-cover' src={imageSrc} alt={title} />
			<div className='absolute bottom-0 left-0 px-4 py-3 bg-white'>{title}</div>
			{description && (
				<div className='absolute top-0 -left-full w-[70%] h-full bg-white opacity-100 items-center justify-center p-5 hidden group-hover:flex group-hover:left-0 transition ease-in-out duration-300'>
					<div>
						<h2 className='  text-base md:text-lg font-bold mb-2'>{title}</h2>
						{subTitle && <p className='text-sm font-medium mb-2 text-green-800'>{subTitle}</p>}
						<p className='line-clamp-2 md:line-clamp-none'>{description}</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default GridImage;
