/** @format */
import React from 'react';
import { useParams } from 'react-router-dom';
import Background from '../assets/hero.jpeg';
import spaColor from '../assets/spaColor.png';
import poolColor from '../assets/poolColor.png';
import game from '../assets/gameRoom.png';
import cozy from '../assets/cozy.png';
import Room from '../assets/brownie-s-guesthouse-rooms.jpeg';
import SectionTitle from './utils/sectionTitle';
import DownArrow from '../assets/down-arrow.svg';
import { GalleryCarousel } from './utils/carousel';
import { useNavigate } from 'react-router-dom';
import Standard from '../assets/brownie-s-guesthouse-rooms.jpeg';
import Standard_1 from '../assets/standard_1.png';
import Standard_2 from '../assets/standard_2.png';
import Standard_3 from '../assets/standard_3.png';
import Standard_4 from '../assets/standard_4.jpeg';
import Standard_5 from '../assets/standard_5.jpeg';
import Deluxe_1 from '../assets/deluxe_1.png';
import Deluxe from '../assets/deluxe.png';
import Deluxe_2 from '../assets/deluxe_2.png';
import Deluxe_3 from '../assets/deluxe_3.png';
import coffee from '../assets/coffee.png';
import wifi from '../assets/wifi.png';
import bath from '../assets/bathroom.png';
import ScrollToTopButton from './utils/scrollToTop';
// Define the Gallery interface
interface Gallery {
	id: number;
	image: string;
	alt: string;
}

// Define the gallery items for the 'standard' room
const standardGalleryItems: Gallery[] = [
	{
		id: 1,
		image: Standard_1,
		alt: 'Standard Room 1',
	},
	{
		id: 2,
		image: Standard_2,
		alt: 'Standard Room 2',
	},
	{
		id: 3,
		image: Standard_3,
		alt: 'Standard Room 3',
	},
	{
		id: 4,
		image: Standard,
		alt: 'Standard Room 4',
	},
	{
		id: 5,
		image: Standard_4,
		alt: 'Standard Room 5',
	},
	{
		id: 6,
		image: Standard_5,
		alt: 'Standard Room 6',
	},
	// Add more images for the standard room as needed
];

// Define the gallery items for the 'triple-room' room
const deluxeGalleryItems: Gallery[] = [
	{
		id: 1,
		image: Deluxe_1,
		alt: 'Deluxe_1',
	},
	{
		id: 2,
		image: Deluxe_2,
		alt: 'Deluxe_2',
	},
	{
		id: 3,
		image: Deluxe_3,
		alt: 'Deluxe_3',
	},
	{
		id: 4,
		image: Deluxe,
		alt: 'Deluxe_4',
	},
];
const superDeluxeGalleryItems: Gallery[] = [
	{
		id: 1,
		image: Room,
		alt: 'Queen Room Balcony 1',
	},
	{
		id: 2,
		image: Room,
		alt: 'Queen Room Balcony 1',
	},
	{
		id: 3,
		image: Room,
		alt: 'Queen Room Balcony 1',
	},
];
const superiorRoomGalleryItems: Gallery[] = [
	{
		id: 1,
		image: Room,
		alt: 'Queen Room Balcony 1',
	},
	{
		id: 2,
		image: Room,
		alt: 'Queen Room Balcony 1',
	},
	{
		id: 3,
		image: Room,
		alt: 'Queen Room Balcony 1',
	},
];

const SpecificRoom: React.FC = () => {
	const navigate = useNavigate();
	const { roomType } = useParams<{ roomType?: string }>();

	let heading = '';
	let residentsSingle = '';
	let residentsDouble = '';
	let nonResidentsSingle = '';
	let nonResidentsDouble = '';
	let galleryItems: Gallery[] = [];

	switch (roomType) {
		case 'standard':
			heading = 'Standard';
			galleryItems = standardGalleryItems;
			residentsSingle = 'Kshs. 5,000';
			residentsDouble = 'Kshs. 6,000';
			nonResidentsSingle = '$70';
			nonResidentsDouble = '$80';
			break;
		case 'deluxe':
			heading = 'Deluxe';
			galleryItems = deluxeGalleryItems;
			residentsSingle = 'Kshs. 6,000';
			residentsDouble = 'Kshs. 7,000';
			nonResidentsSingle = '$80';
			nonResidentsDouble = '$90';
			break;
		case 'super-deluxe':
			heading = 'Super Deluxe';
			galleryItems = superDeluxeGalleryItems;
			residentsSingle = 'Kshs. 7,000';
			residentsDouble = 'Kshs. 8,000';
			nonResidentsSingle = '$80';
			nonResidentsDouble = '$90';
			break;
		case 'superior':
			heading = 'Superior';
			galleryItems = superiorRoomGalleryItems;
			residentsSingle = 'Kshs. 8,000';
			residentsDouble = 'Kshs. 9,000';
			nonResidentsSingle = '$100';
			nonResidentsDouble = '$110';
			break;
		default:
			// Redirect to the /rooms page if roomType is not recognized
			navigate('/not_found');
			return null;
	}
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[40vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex '>
						<div className='mx-auto  font-bold text-white w-[400px] relative z-20 text-center'>
							<SectionTitle heading={heading} subheading='A natural approach to better health.' />
						</div>
					</section>
				</div>
				{/* Room Info */}
				<section className=' my-20 border-b-2 border-solid pb-20'>
					<div className='grid md:grid-cols-2 grid-cols-1 gap-10 mx-10 md:mx-20 '>
						<div className='w-full h-full my-auto text-center'>
							<h1 className='uppercase font-bold'>Residents</h1>
							<p>Single Occupancy: {residentsSingle}</p>
							<p>Double Occupancy: {residentsDouble}</p>
						</div>
						<div className='w-full h-full mx-auto my-auto  text-center'>
							<h1 className='uppercase font-bold'>Non-Residents</h1>
							<p>Single Occupancy: {nonResidentsSingle}</p>
							<p>Double Occupancy: {nonResidentsDouble}</p>
						</div>
					</div>
				</section>
				<section className='my-20 text-center mx-10 space-y-10'>
					<p className='text-xl font-medium'>
						All our rooms feature, as standard, the finest satin bed linen, electric kettles with complementary coffee and tea supplies, hairdryers and insect repellent. Free, high-quality
						fibre wifi is also available throughout the premises.
					</p>
					<img src={DownArrow} alt='Arrow' className='mx-auto h-20 w-10' />
				</section>

				{/* Gallery */}
				<section>
					<GalleryCarousel galleryItems={galleryItems} />
				</section>
				{/* <section className='grid grid-col-1 md:grid-cols-2 lg:gap-32 gap-10 pt-20 mx-10 lg:mx-20 lg:px-20'>
					<div className='block md:hidden w-full h-full '>
						<img src={Background} alt='' className='w-full h-full' />
					</div>
					<div
						className='hidden md:block w-full h-full lg:min-h-[683px]  '
						style={{
							backgroundImage: `url(${Background})`, // Set the background image
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
						}}></div>
					<div className='my-auto space-y-5'>
						<h1 className='  md:text-4xl text-2xl  font-medium'>
							Ambiance & Decor of <br />
							{heading} Room:
						</h1>
						<div className=' lg:min-w-80 mx-auto'>
							<ul className='list-image-[url(../assets/list_Icon.png)] list-outside'>
								<li>Curated original works of art from the Baccarat collection and crystal sconces glowing on the walls.</li>
								<li>A subtle palette of champagne, ivory, platinum, stainless steel and chocolate brown.</li>
								<li>A sumptuous bed is clothed in Mascioni jacquard linens and a cashmere throw.</li>
								<li>A red lacquer minibar filled with Baccarat crystal stemware offers delicacies from Maison Ladurée.</li>
							</ul>
						</div>
					</div>
				</section> */}
				<section className='grid grid-col-1 md:grid-cols-2 lg:gap-32 gap-10 py-10  lg:px-20 mx-10 lg:mx-20'>
					<div className='block md:hidden w-full h-full '>
						<img src={Background} alt='' className='w-full h-full' />
					</div>
					<div className='my-auto space-y-5'>
						{/* <h1 className='lg:text-[5rem] font-medium text-center'> */}
						<h1 className='  md:text-4xl text-2xl  font-medium'>
							Exclusive & Complimentary <br /> Services.
						</h1>
						<div className=' lg:min-w-80 mx-auto'>
							<div className='grid grid-cols-2 md:grid-cols-4 gap-5 mx-10 my-10'>
								{heading === 'Standard' || heading === 'Deluxe' ? (
									<>
										<div className='w-full'>
											<img src={cozy} alt='Icon' className='w-14 h-12 mx-auto' />
											<h1 className='text-center'>Cozy</h1>
										</div>
										<div className='w-full'>
											<img src={bath} alt='Icon' className='w-14 h-12 mx-auto' />
											<h1 className='text-center'>Bath</h1>
										</div>
										<div className='w-full'>
											<img src={wifi} alt='Icon' className='w-14 h-12 mx-auto' />
											<h1 className='text-center'>WiFi</h1>
										</div>
										<div className='w-full'>
											<img src={coffee} alt='Icon' className='w-14 h-12 mx-auto' />
											<h1 className='text-center'>Coffee</h1>
										</div>
									</>
								) : (
									<>
										<div className='w-full'>
											<img src={cozy} alt='Icon' className='w-16 h-12 mx-auto' />
											<h1 className='text-center'>Cozy</h1>
										</div>
										<div className='w-full'>
											<img src={spaColor} alt='Icon' className='w-16 h-12 mx-auto' />
											<h1 className='text-center'>Spa</h1>
										</div>
										<div className='w-full'>
											<img src={poolColor} alt='Icon' className='w-16 h-12 mx-auto' />
											<h1 className='text-center'>Pool</h1>
										</div>
										<div className='w-full'>
											<img src={game} alt='Icon' className='w-16 h-12 mx-auto' />
											<h1 className='text-center'>Game</h1>
										</div>
									</>
								)}
							</div>
							<p>
								At Brownie's, we believe that hospitality is an art form and we strive to embody it in every interaction with our guests. From the moment you step through our doors,
								you'll be enveloped in a warm and welcoming atmosphere, where our attentive staff is dedicated to making your stay exceptional.
							</p>
						</div>
					</div>
					<div
						className='hidden md:block w-full h-full lg:min-h-[683px]  '
						style={{
							backgroundImage: `url(${Background})`, // Set the background image
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
						}}></div>
				</section>
			</div>
			<ScrollToTopButton />
		</div>
	);
};
export default SpecificRoom;
