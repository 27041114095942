/** @format */
import SectionTitle from './utils/sectionTitle';
import Background from '../assets/hero.jpeg';
import ScrollToTopButton from './utils/scrollToTop';
import family from '../assets/family.png';
import confetti from '../assets/confetti.png';
import pool_2 from '../assets/pool_2.jpg';
import pool_1 from '../assets/pool_1.jpeg';
import pool_3 from '../assets/pool_2.jpeg';
import pool_4 from '../assets/pool_3.jpeg';
import trampoline_1 from '../assets/trampoline_1.jpeg';
import trampoline_2 from '../assets/trampoline_2.jpeg';
import trampoline_3 from '../assets/trampoline_3.jpeg';
import trampoline_4 from '../assets/trampoline_4.jpeg';
import pool from '../assets/pool.png';

import trampoline from '../assets/family-activities-nakuru-trampoline.png';
import { ImageSlider } from './utils/carousel';

interface Gallery {
	id: number;
	image: string;
	alt: string;
}

// Define the gallery items for the 'standard' room
const poolItems: Gallery[] = [
	{
		id: 1,
		image: pool_1,
		alt: 'pool',
	},
	{
		id: 2,
		image: pool_2,
		alt: 'pool',
	},
	{
		id: 3,
		image: pool_3,
		alt: 'pool',
	},
	{
		id: 4,
		image: pool_4,
		alt: 'pool',
	},
];
const trampolineItems: Gallery[] = [
	{
		id: 1,
		image: trampoline_1,
		alt: 'trampoline',
	},
	{
		id: 2,
		image: trampoline_2,
		alt: 'trampoline',
	},
	{
		id: 3,
		image: trampoline_3,
		alt: 'trampoline',
	},
	{
		id: 4,
		image: trampoline_4,
		alt: 'trampoline',
	},
	{
		id: 5,
		image: trampoline,
		alt: 'trampoline',
	},
];
const Family: React.FC = () => {
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[60vh] md:h-[50vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex px-10'>
						<div className='mx-auto  font-bold text-white w-[400px] relative z-20 text-center'>
							<SectionTitle heading='Family / Kid Friendly' subheading='Families with children can also have a wonderful time' />
						</div>
					</section>
				</div>
				{/* Spa */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='' subheading='Baboon Cliff located in the iconic Lake Nakuru National Park' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20'>
						{/* The City */}
						{/* <div className='block md:hidden col-span-2 w-full h-full md:max-h-[450px]'>
							<img src={family} alt='Spa' className='w-full h-full object-cover' />
						</div> */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={confetti} alt='cliff icon' className='w-18 h-16 mx-auto' />
							<h1 className='text-2xl '>Bike Riding</h1>
							<p className='max-w-[400px] mx-auto'>
								Whether you're an avid cyclist or a casual rider, our well-maintained bicycles are perfect for traversing the scenic pathways and tranquil streets surrounding Brownie's
								Hotel. Immerse yourself in the tranquil beauty of our neighbourhood, while enjoying a fun and healthy activity.
							</p>
						</div>
						<div className=' w-full h-full md:max-h-[450px]'>
							<img src={family} alt='Spa' className='w-full h-full object-contain' />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Gym */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Golf' subheading='In close proximity to one of the best Golf courses in Kenya' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* cycling */}
						<div className=' hidden md:block w-full h-full max-h-[450px]'>
							{/* <img src={pool_2} alt='Cycling' className='w-full h-full object-cover' /> */}
							<ImageSlider sliderItems={poolItems} />
						</div>
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={pool} alt='cycling icon' className='w-32 h-28 mx-auto' />
							<h1 className='text-2xl '>Adult & Baby Pool</h1>
							<p className='max-w-[400px] mx-auto'>
								Soak up the sun's warmth on comfortable poolside loungers, surrounded by lush greenery and the gentle sounds of nature. Our dedicated baby pool ensures that even the
								youngest guests can enjoy the fun of swimming. Designed for children aged 2-7, this shallow pool offers a safe and playful environment for little ones to splash and
								play under the watchful eyes of their parents.
							</p>
						</div>
						<div className=' block md:hidden w-full h-full max-h-[450px]'>
							{/* <img src={pool_2} alt='Cycling' className='w-full h-full object-contain' /> */}
							<ImageSlider sliderItems={poolItems} />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Gym */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Lakes' subheading='In the middle of two lakes.' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							<img src={confetti} alt='Trampoline icon' className='w-18 h-16 mx-auto' />
							<h1 className='text-2xl '>Trampoline</h1>
							<p className='max-w-[400px] mx-auto'>
								Get ready for thrills and laughter at Brownie's Hotel's exhilarating trampoline park. Unleash your inner child and soar through the air on our trampoline, enjoying the
								sensation of weightlessness and the pure joy of bouncing. Whether you're mastering new flips and tricks or simply bouncing for fun, our trampoline park provides endless
								entertainment for guests of all ages.
							</p>
						</div>
						<div className='w-full h-full md:max-h-[450px] mb-10'>
							{/* <img src={trampoline} alt='Cycling' className='w-full h-full object-contain' /> */}
							<ImageSlider sliderItems={trampolineItems} />
						</div>
						{/* End */}
					</div>
				</section>
			</div>
			<ScrollToTopButton />
		</div>
	);
};

export default Family;
