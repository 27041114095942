/** @format */

import React, { useEffect, useState } from 'react';

const ScrollToTopButton: React.FC = () => {
	const [isVisible, setIsVisible] = useState(false);

	const handleScroll = () => {
		if (window.scrollY > 100) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<button
			className={`${isVisible ? 'block' : 'hidden'} fixed bottom-5 right-5 bg-white text-white px-2 py-2 shadow-md hover:bg-button_active transform hover:scale-105 transition-all duration-300`}
			onClick={scrollToTop}>
			<svg fill='#000000' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 330' className='w-5 h-5'>
				<path
					id='XMLID_224_'
					d='M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
	l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
	C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z'
				/>
			</svg>
		</button>
	);
};

export default ScrollToTopButton;
