/** @format */

import SectionTitle from './utils/sectionTitle';
import Background from '../assets/hero.jpeg';
import ScrollToTopButton from './utils/scrollToTop';
import companyRetreat from '../assets/company-retreat-nakuru-hotel.png';
import trainingSessions from '../assets/corporate-training-nakuru-hotel.png';
import boardMeeting from '../assets/board-meeting-nakuru-hotel.png';
const Meetings: React.FC = () => {
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[40vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex '>
						<div className='mx-auto  font-bold text-white w-[400px] relative z-20 text-center'>
							<SectionTitle heading='Meetings' subheading='You can also host your meetings here.' />
						</div>
					</section>
				</div>
				{/* Spa */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='' subheading='Baboon Cliff located in the iconic Lake Nakuru National Park' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20'>
						{/* The City */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							{/* <img src={confetti} alt='cliff icon' className='w-18 h-16 mx-auto' /> */}
							<h1 className='text-2xl '>Company Retreat</h1>
							<p className='max-w-[400px] mx-auto'>
								Escape the ordinary and redefine team-building with our exclusive company retreats at Brownie's. Our retreats offer a perfect blend of relaxation, rejuvenation, and
								strategic bonding activities to bring your team closer than ever.
							</p>
						</div>
						<div className=' w-full h-full md:max-h-[700px] max-h-[450px]'>
							<img
								src={companyRetreat}
								alt='Spa'
								className='w-full h-full object-contain
                            '
							/>
						</div>
						{/* End */}
					</div>
				</section>
				{/* Gym */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Golf' subheading='In close proximity to one of the best Golf courses in Kenya' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* cycling */}
						<div className=' hidden md:block  w-full h-full max-h-[700px]'>
							<img src={trainingSessions} alt='Cycling' className='w-full h-full object-contain' />
						</div>
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							{/* <img src={birthday} alt='cycling icon' className='w-24 h-20 mx-auto' /> */}
							<h1 className='text-2xl '>Training Sessions</h1>
							<p className='max-w-[400px] mx-auto'>
								Create an interactive learning environment for your team with our adaptable meeting spaces. Enjoy the flexibility of layouts, audiovisual support, and catering options
								that make training sessions both engaging and effective.
							</p>
						</div>
						<div className=' block md:hidden w-full h-full max-h-[450px]'>
							<img src={trainingSessions} alt='Cycling' className='w-full h-full object-cover' />
						</div>
						{/* End */}
					</div>
				</section>
				{/* Gym */}
				<section className='md:mx-20 mx-5 my-20'>
					{/* <SectionTitle heading='Lakes' subheading='In the middle of two lakes.' /> */}
					<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 '>
						{/* cycling */}
						<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
							{/* <img src={confetti} alt='cycling icon' className='w-18 h-16 mx-auto' /> */}
							<h1 className='text-2xl '>Board Meeting</h1>
							<p className='max-w-[400px] mx-auto'>
								Foster innovation and strategic discussions in an intimate and professional environment. Our boardrooms are designed to enhance collaboration and decision-making,
								ensuring your leadership team stays focused on the agenda.
							</p>
						</div>
						<div className=' w-full h-full max-h-[450px]'>
							<img src={boardMeeting} alt='Cycling' className='w-full h-full object-cover' />
						</div>
						{/* End */}
					</div>
				</section>
			</div>
			<ScrollToTopButton />
		</div>
	);
};

export default Meetings;
