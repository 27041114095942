/** @format */

import React from 'react';
import Background from '../assets/hero.jpeg';
import HotelNakuru from '../assets/PHOTO-2023-11-25-10-40-43.jpg';
import ScrollToTopButton from './utils/scrollToTop';
import restaurantIcon from '../assets/restaurantIcon.png';
import barIcon from '../assets/BarIcon.png';
import cityIcon from '../assets/cityIcon.png';
import cozyIcon from '../assets/cozy_grey.png';
import gameIcon from '../assets/game_Grey.png';
import poolIcon from '../assets/pool.png';
import lounge from '../assets/lounge.png';
import SectionTitle from './utils/sectionTitle';
import ExploreGridItem from './utils/exploreGridItem';
import lounge_2 from '../assets/lounge_2.png';
import garden from '../assets/garden.png';
import restaurant from '../assets/restaurant_2.png';
import hotel_1 from '../assets/hotel_1.jpeg';
import hotel_2 from '../assets/hotel_2.jpeg';
import { ImageSlider } from './utils/carousel';

interface Gallery {
	id: number;
	image: string;
	alt: string;
}

// Define the gallery items for the 'standard' room
const hotelItems: Gallery[] = [
	{
		id: 1,
		image: hotel_1,
		alt: 'hotel',
	},
	{
		id: 2,
		image: hotel_2,
		alt: 'hotel',
	},
	{
		id: 3,
		image: HotelNakuru,
		alt: 'hotel',
	},
];
const Hotel: React.FC = () => {
	return (
		<div className='font-sans'>
			<div>
				<div
					className='h-[90vh]  relative z-0 top-0 pt-40 pb-5 '
					style={{
						backgroundImage: `url(${Background})`, // Set the background image
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}>
					{/* Top of page */}
					<div className='bg-black bg-opacity-40 flex flex-col absolute top-0 bottom-0 w-full h-full'></div>
					<section className='flex '>
						<div className='mx-auto my-[20vh] font-bold text-white w-[400px] relative z-20 text-center'>
							<h1 className='lg:text-[4rem] text-[2rem]'>Our Hotel</h1>
							<p> Live and feel like a local in your own luxury apartment in Nakuru</p>
						</div>
					</section>
				</div>
			</div>
			<div className=' grid md:grid-cols-2 gap-5 grid-cols-1  my-20 md:mx-20 mx-5'>
				{/* The City */}
				<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
					<img src={cityIcon} alt='Restaurant icon' className='w-24 h-24 mx-auto' />
					<h1 className='text-2xl '>Hotel In Nakuru</h1>
					<p className='max-w-[400px] mx-auto'>
						Embark on a Serene Getaway in Nakuru: Nestled in the heart of Nakuru, our hotel beckons you to experience a haven of relaxation and comfort. Brownie's is located in the
						serenely sleepy Lockwood Estate, a gated residential neighbourhood about twelve kilometers away from Nakuru town, and which overlooks the beautiful slopes of the Menengai
						Hills. To get there, one drives by the gate of the prestigious Greensteds International School, and on for a further five hundred meters.
					</p>
				</div>
				<div className='  w-full h-full min-h-[450px]'>
					{/* <img src={HotelNakuru} alt='Bar' className='w-full h-full' /> */}
					<ImageSlider sliderItems={hotelItems} />
				</div>
				{/* End */}
				{/* Transportation */}
				<div className='hidden md:block w-full h-full md:min-h-[450px]'>
					<img src={lounge_2} alt='Lounge' className='w-full h-full' />
				</div>
				<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
					<img src={lounge} alt='Hotel icon' className='w-20 h-18 mx-auto' />
					<h1 className='text-2xl '>The Hibiscus Lounge</h1>
					<p className='max-w-[400px] mx-auto'>
						Modelled on a typical family room, our Hibiscus lounge is the very definition of the word cozy. Featuring an ensemble of Victorian style sofa set and wing chairs, a beautiful
						feature wall with a 50 inch TV screen and surround sound home theatre, matching fireplace, small bar and counter and wooden floor, the Hibiscus lounge is an ideal place to come
						and meet other guests, enjoy a drink or meal with your family or friends and generally relax and unwind.
					</p>
				</div>
				<div className='block md:hidden w-full h-full md:min-h-[450px]'>
					<img src={lounge_2} alt='Lounge' className='w-full h-full' />
				</div>
				{/* End */}
				{/* Restautrant */}
				<div className=' lg:w-fit w-full text-center space-y-5  mx-auto my-auto'>
					<img src={restaurantIcon} alt='Restaurant icon' className='w-32 h-28 mx-auto' />
					<h1 className='text-2xl '>Exceptional Food</h1>
					<p className='max-w-[400px] mx-auto'>
						The unique flavors you will find in our restaurant will bring you one step closer to feeling like a local. You can grab a quick croissant or sit down for an exceptional full
						course dinner.
					</p>
					<a href={require('../assets/Brownies-Food-Menu.pdf')} className='underline' target='_blank ' rel='noopener noreferrer'>
						Download Menu
					</a>
				</div>
				<div className=' w-full h-full max-h-[450px]'>
					<img src={restaurant} alt='Restaurant' className='w-full h-full object-contain' />
				</div>
				{/* End */}
			</div>

			{/* Include the Rooms slides here */}

			{/* End */}

			{/* Explore the hotel */}
			<section className='my-20 flex flex-col mx-5 md:mx-20 space-y-5'>
				<SectionTitle heading='Explore our hotel' subheading={`PEOPLE, PLACES AND HAPPENINGS`} />
				<div className='grid grid-cols-1 gap-0 divide-y divide-solid shadow-xl'>
					<div className='grid md:grid-cols-3 gap-0 divide-y md:divide-y-0 md:divide-x divide-solid'>
						<ExploreGridItem
							iconSrc={restaurantIcon}
							title='Culinary Experience'
							description="Immerse yourself in the distinctive flavors of our eatery, a journey that effortlessly connects you to the essence of local culture. Whether it's a swift croissant on the go or a leisurely indulgence in an extraordinary full-course dinner, our restaurant caters to every palate."
						/>
						<ExploreGridItem
							iconSrc={barIcon}
							title='Bar Drinks'
							description='Discover a curated selection of bar drinks that transcend the ordinary. Our skilled bartenders bring you expertly crafted beverages, ensuring each sip is a delightful experience. Whether you prefer a classic cocktail or an inventive creation, our bar is your haven for elevated libations.'
						/>
						<ExploreGridItem
							iconSrc={cozyIcon}
							title='Cozy Rooms'
							description='Retreat to the inviting comfort of our cozy rooms, where thoughtful design meets functionality. Each space is a sanctuary, offering a relaxing atmosphere that goes beyond mere accommodation. Unwind and recharge in style, surrounded by the comforts of home.'
						/>
					</div>
					<div className='grid md:grid-cols-3 gap-0 divide-y md:divide-y-0 md:divide-x divide-solid'>
						<ExploreGridItem
							iconSrc={gameIcon}
							title='Game Room'
							description="Fuel your sense of adventure in our dynamic game room. There's something for every gamer in the family. It's not just a game room; it's a space for laughter, bonding, and creating lasting memories."
						/>
						<ExploreGridItem
							iconSrc={garden}
							title='Lush Garden'
							description='Escape to our lush garden oasis, a serene retreat within the heart of our establishment. Immerse yourself in the beauty of nature, offering a peaceful respite for relaxation and reflection. Whether you seek a quiet spot to unwind or a scenic backdrop for special moments, our garden awaits.'
						/>
						<ExploreGridItem
							iconSrc={poolIcon}
							title='Swimming Pool'
							description='Beat the heat and dive into the refreshing luxury of our swimming pool. Perfect for all ages, our temperature-controlled pool provides a haven for relaxation and enjoyment. Soak up the sun, take a refreshing swim, or simply lounge by the poolside – the choice is yours.'
						/>
					</div>
				</div>
			</section>
			<ScrollToTopButton />
		</div>
	);
};

export default Hotel;
